$px1: 1px;
$px2: 2px;
$px4: 4px;
$px8: 8px;
$px12: 12px;
$px16: 16px;
$px20: 20px;
$px24: 24px;
$px32: 32px;
$px48: 48px;
$px64: 64px;
$px72: 72px;
$px96: 96px;
$px144: 144px;
$px192: 192px;
$px240: 240px;
$px320: 320px;
$px384: 384px;
$px496: 496px;
$px720: 720px;
$px960: 960px;
$px1024: 1024px;
