
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.introduction-step {
  max-width: 720px;

  &__description {
    margin-bottom: $px16;

    @include font-large;
  }

  &__snapshot-image {
    width: 100%;
    max-width: 426px;
    max-height: 361px;

    @include breakpoint($sm) {
      width: 426px;
      height: 361px;
    }
  }
}

