
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.job-ad-form {
  &__errors {
    margin-bottom: $px24;
  }

  &__form-group {
    display: flex;
    flex-direction: column;
    position: relative;

    & + & {
      margin-top: $px32;
    }

    .CodeMirror,
    .CodeMirror-scroll {
      height: auto !important;
      min-height: $px64 !important;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  textarea {
    width: 100%;
    padding: $px8;

    border: $px1 solid $grey-8;
    border-radius: $px4;
    outline: none;

    @include font-regular;

    appearance: none;

    &::placeholder {
      @include font-regular;
      color: $grey-7;
    }

    &.error {
      border-color: $red-5;
    }
  }

  select {
    min-width: $px240;
    padding: $px8 $px16;

    border: $px2 solid $grey-7;
    border-radius: $px4;

    outline: none;
  }

  textarea {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  label {
    margin-bottom: $px2;

    @include font-label;
  }

  &__form-group-error {
    margin-top: $px8;

    @include font-small;
    color: $red-5;
  }

  &__form-group-hint {
    margin-top: $px8;

    @include font-small;
    color: $grey-6;
  }

  &__status-bar {
    display: flex;
    justify-content: space-between;
  }

  &__characters-counter {
    margin-top: $px8;
    margin-left: $px24;

    @include font-small;
    color: $grey-6;
    white-space: nowrap;
  }

  &__submit {
    margin-top: $px8;

    outline: none;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &__toggle-password {
    position: absolute;
    top: 50%;
    right: $px8;
  }

  &__eye-icon {
    width: $px24;
    height: $px24;
    display: none;

    &--active {
      display: block;
    }
  }

  &__horizontal-select {
    display: flex;
  }

  &__horizontal-select-option {
    padding: $px8;

    background-color: $grey-10;

    cursor: pointer;

    &:first-child {
      border-radius: $px8 0 0 $px8;
    }

    &:last-child {
      border-radius: 0 $px8 $px8 0;
    }

    &--active {
      background-color: $grey-8;

      cursor: auto;
    }

    sup {
      margin-left: $px2;
      padding: $px2 $px4;

      border-radius: $px4;

      background-color: $red-5;

      @include font-legal;
      color: $white;
    }
  }

  &__file-drag-and-drop {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $px96;
    border-radius: $px8;

    background-color: $grey-9;

    color: $grey-5;

    cursor: pointer;
  }

  &__company-logo-preview {
    width: $px64;
    height: $px64;
    margin-top: $px8;

    border-radius: $px8;
  }

  &__generic-error {
    padding: $px16;
    margin-top: $px24;

    border-radius: $px8;

    background-color: $red-9;
  }

  &__errors {
    padding: $px16;
    margin-top: $px24;

    border-radius: $px8;

    background-color: $red-9;
  }

  &__error {
    &:not(:last-child) {
      margin-bottom: $px24;
    }
  }

  &__error-field {
    margin-bottom: $px2;

    @include font-label;
  }

  &__error-message {
    @include font-small;
  }
}
