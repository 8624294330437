
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.question-subscription {
  padding: $px12;

  border-radius: $px4;

  background-color: $grey-10;

  &__headline {
    margin-bottom: $px12;

    @include font-small;
  }

  &__description {
    margin-bottom: $px12;

    @include font-small;
  }

  &__cta {
    align-items: center;
    justify-content: center;
  }

  &__cta-body {
    display: flex;
    align-items: center;
  }

  &__cta-icon {
    width: $px16;
    height: $px16;
    margin-right: $px8;
  }
}
