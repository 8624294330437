
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.search-field {
  padding: 0 $px16;

  border-radius: $px8;

  background-color: $grey-10;

  &__container {
    display: flex;
    align-items: center;
  }

  &__input {
    width: 100%;
    padding: $px16 $px16;

    border: 0;
    outline: none;

    @include font-large;

    background-color: $grey-10;
  }

  &__search-icon {
    width: $px24;
    height: $px24;

    color: $grey-5;

    cursor: pointer;
  }

  &__close-icon {
    width: $px24;
    height: $px24;

    color: $grey-5;

    cursor: pointer;

    &:hover {
      color: $grey-4;

      transform: scale(1.05);
    }
  }
}
