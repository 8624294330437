
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.query-result-wrapper {
  position: relative;

  &__header {
    position: relative;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  &__headline {
    margin-bottom: $px8;
  }

  &__result {
    width: 100%;
    overflow: auto;

    border-radius: $px4;
    border: $px1 solid $grey-9;

    font-size: 14px;

    &::-webkit-scrollbar {
      width: $px8;
      height: $px8;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: $px4;

    	background-color: $grey-8;
    }

    &--scrolled {
      table {
        thead {
          th {
          }
        }
      }
    }
  }

  &__duration {
    position: absolute;
    top: -$px4;
    right: 0;

    @include font-small;
    text-align: right;
    color: $grey-6;

    transform: translate(0, -100%);
  }
}
