
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.selected-text-menu {
  &__icon-wrapper {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    border-radius: 50%;

    background-color: $orange-6;

    cursor: pointer;

    &:hover {
      background-color: $orange-7;
    }

    &:not(:last-child) {
      margin-bottom: $px12;
    }
  }

  &__icon {
    width: $px24;
    height: $px24;
    flex-shrink: 0;

    color: $white;

    &--email {
      width: $px20;
      height: $px20;
    }
  }
}
