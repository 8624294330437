
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.countdown-digits {
  $root: &;

  display: flex;
  flex-direction: column;
  position: relative;

  &__digits {
    display: flex;
    flex-direction: row;
    position: relative;
    padding: $px4;
  }

  &__top-flap {
    display: block;
    position: absolute;
    top: -$px1;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: -1;

    border-radius: $px4 $px4 0 0;

    background-color: $blue-10;
  }

  &__bottom-flap {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: -1;

    border-radius: 0 0 $px4 $px4;

    background-color: $blue-10;
  }

  &__digit {
    @include font-large;
    font-weight: 700;

    &:first-child {
      margin-right: $px4;
    }
  }

  &__label {
    position: absolute;
    bottom: 0;
    left: 50%;

    text-align: center;

    @include font-legal;

    transform: translateX(-50%) translateY(100%);
  }
}
