
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.practice-exercise {
  position: relative;
  padding: $px24;

  border: $px1 solid $grey-9;
  border-radius: $px4;

  &__name {
    display: flex;
    align-items: center;
    margin: 0 0 $px8;
  }

  &__summary {
    margin-bottom: $px16;
  }

  &__info {
    display: flex;
    align-items: center;
    margin-bottom: $px16;

    @include font-small;
  }

  &__info-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $px4 $px12;

    border-radius: $px16;

    background-color: $grey-9;

    &:not(:last-child) {
      margin-right: $px8;
    }
  }

  &__info-badge-icon {
    width: $px16;
    height: $px16;
    margin-right: $px4;
    flex-shrink: 0;
  }

  &__status {
    display: none;
    position: absolute;
    top: -$px1;
    right: -$px1;
    width: $px32;
    height: $px32;
    align-items: center;
    justify-content: center;
    margin-left: $px8;
    flex-shrink: 0;

    border-radius: 0 $px4 0 $px4;

    background-color: $green-7;

    color: $white;

    &--active {
      display: flex;
    }
  }

  &__status-icon {
    width: $px16;
    height: $px16;
  }

  &__cta {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__cta-icon {
    width: $px16;
    height: $px16;
    margin-right: $px8;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__difficutly-icon {
    width: $px16;
    height: $px16;
  }
}
