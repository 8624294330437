
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.search-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2147483650;
  display: flex;

  background-color: rgba($color: $grey-3, $alpha: 0.7);

  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 0 auto;

    transition: all .25s;
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: $px720;
    margin-top: $px72;

    border-radius: $px8;

    background-color: $grey-10;
  }
}
