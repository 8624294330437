
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.practice-dropdown-menu {
  $root: &;

  position: relative;

  cursor: pointer;

  &__container {
    display: flex;
    align-items: center;
    padding: $px8;

    border-radius: $px4;

    background-color: $grey-10;
  }

  &__name {
    margin-right: $px8;
  }

  &__dropdown-icon {
    width: $px24;
    height: $px24;

    transition: transform .3s linear;
  }

  &__dropdown-menu {
    display: none;
  }

  &--active {
    #{$root}__dropdown-icon {
      transform: rotate(180deg);
    }

    #{$root}__dropdown-menu {
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 100;
      margin-top: $px8;
      width: $px240;
      padding: $px8;

      border-radius: $px4;
      box-shadow: 0 $px4 $px16 0 $grey-8;

      background-color: $white;
    }
  }
}
