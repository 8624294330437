
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.page-feedback {
  $root: &;
  position: fixed;
  bottom: $px24;
  right: $px24;
  z-index: 2147483648;

  &__header {
    display: flex;
    justify-content: flex-end;
    padding: $px12;

    border-radius: $px8 $px8 0 0;

    background-color: $grey-10;

    color: $grey-1;

    cursor: pointer;

    &:hover {
      #{$root}__close-icon {
        transform: scale(1.05);
      }
    }
  }

  &__cta {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $px64;
    height: $px64;
    padding: $px24 $px32 $px20;

    border-radius: 50%;

    background-color: $orange-6;

    box-shadow: 0 $px4 $px16 0 $grey-8;
    cursor: pointer;
    transition: all .1s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__feedback {
    border-radius: $px8;
    box-shadow: 0 $px4 $px16 0 $grey-8;

    transition: bottom 1s;
  }

  &__form {
    display: flex;
    flex-direction: column;
    padding: $px12;

    border-radius: 0 0 $px8 $px8;

    background-color: $grey-10;
  }

  &__name {
    margin-bottom: $px16;
    padding: $px8;

    border: $px1 solid $grey-8;
    border-radius: $px4;
    outline: none;
    appearance: none;
  }

  &__email {
    margin-bottom: $px16;
    padding: $px8;

    border: $px1 solid $grey-8;
    border-radius: $px4;
    outline: none;
    appearance: none;
  }

  &__text-area {
    width: 100%;
    height: $px144;
    margin-bottom: $px16;
    padding: $px8;

    border: $px1 solid $grey-8;
    border-radius: $px4;
    outline: none;
    appearance: none;

    @include breakpoint($sm) {
      width: $px320;
    }
  }

  &__close-icon {
    width: $px24;
    height: $px24;

    color: $grey-1;

    cursor: pointer;
    transition: all .2s ease-in-out;
  }

  &__comment-icon {
    width: 26px;
    height: 26px;
    flex-shrink: 0;

    color: $white;

    transform: scaleX(-1);
  }

  &--active {
    width: 100%;
    padding: 0 $px16;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint($sm) {
      width: auto;
      padding: 0;
      left: auto;
      transform: none;
    }
  }

  &--small {
    #{$root}__cta {
      width: $px48;
      height: $px48;
      padding: $px20 $px24 $px16;
    }

    #{$root}__comment-icon {
      width: $px20;
      height: $px20;
    }
  }
}
