
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.mock-interview {
  $root: &;

  position: relative;
  padding-bottom: $px48;

  &__container {
    position: relative;
    padding-bottom: $px64;

    @include breakpoint($sm) {
      padding-bottom: 0;
    }

    @include breakpoint($md) {
      padding-top: $px32;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: $px24 0;
  }

  &__items-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: $px8;
    margin-bottom: $px24;

    border-bottom: $px1 solid $grey-9;
  }

  &__full-screen-icon {
    width: $px24;
    height: $px24;

    color: $grey-8;

    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      color: $grey-6;

      transform: scale(1.2);
    }
  }

  &:fullscreen {
    padding: $px24;
    overflow-y: scroll;
    position: relative;
    z-index: 1;

    background-color: $white;
  }
}
