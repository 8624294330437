
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.data-level-step {
  &__levels {
    display: flex;
    flex-direction: column;
    max-width: $px720;
    gap: $px16;

    @include breakpoint($sm) {
      width: 100%;
      flex-direction: row;
    }
  }
}
