
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.screenshot-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__images {
    margin-bottom: $px16;
  }

  &__image-container {
    display: none;

    &--active {
      display: block;
    }
  }

  &__image {
    display: block;
    width: 100%;
    margin-bottom: $px4;

    cursor: pointer;
  }

  &__caption {
    @include font-small;
  }

  &__nav {
    display: flex;
    align-items: center;
  }

  &__nav-item {
    width: $px8;
    height: $px8;

    border-radius: 50%;

    background-color: $grey-6;

    cursor: pointer;

    &--active {
      width: $px12;
      height: $px12;
    }

    &:not(:last-child) {
      margin-right: $px8;
    }
  }
}
