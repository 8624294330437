
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.subscription-pricing-plan {
  $root: &;

  position: relative;
  padding: $px24;

  border-radius: $px8;

  background-color: $grey-10;

  &__name {
    @include h3;
    font-weight: 700;
  }

  &__final-price {
    font-size: $px32;
    line-height: $px32;
    font-weight: 700;
  }

  &__per-month {
    margin-top: auto;

    font-size: $px20;
    line-height: 1;
  }

  &__billing-price {
    margin-top: $px8;

    @include font-small;
    text-align: center;

    &--striked {
      color: $grey-2;
      text-decoration: line-through;
    }
  }

  &__yearly-savings {
    position: absolute;
    right: $px16;
    top: 0;
    transform: translateY(-50%);
    padding: $px4 $px12;

    border-radius: $px16;

    background-color: $purple-5;

    color: $white;
    font-size: 15px;
    font-weight: 700;
  }

  &__plan-description-line {
    display: flex;
    align-items: flex-start;
    gap: $px8;
    margin-bottom: $px8;
  }

  &__plan-description-check-mark {
    flex-shrink: 0;
    width: $px16;
    height: $px16;
    margin-top: $px4;

    color: $grey-5;
  }
}
