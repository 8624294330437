
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.welcome-checklist-item {
  display: flex;
  align-items: center;

  text-decoration: none;

  &__index {
    width: $px32;
    height: $px32;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: $px12;

    border-radius: 50%;

    background-color: $grey-8;

    @include h4;
    color: $white;

    &--finished {
      background-color: $blue-6;

      color: $white;
    }
  }

  &__name {
    margin: 0;
    padding: 0;
  }
}
