
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.set-goal-step {
  max-width: 720px;

  &__description {
    margin-bottom: $px32;

    @include font-large;
  }

  &__goals {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $px32;
  }

  &__goal {
    margin-bottom: $px16;

    &:not(:last-child) {
      margin-right: $px16;
    }
  }

  &__goal-input {
    margin-bottom: $px16;
    padding: $px8;

    outline: none;
    appearance: none;
    border: 0;
    border-bottom: $px1 solid $grey-9;

    color: $grey-1;

    &::placeholder {
      @include font-btn;
      color: $grey-7;
    }
  }
}
