
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.practice-exercises {
  &__menu {
    display: none;

    @include breakpoint($md) {
      display: block;
      margin-bottom: $px24;
    }
  }

  &__exercise {
    &:not(:last-child) {
      margin-bottom: $px24;
    }
  }
}
