
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.product-price {
  $root: &;

  display: flex;
  align-items: center;
  justify-content: center;

  &__original-price {
    margin-right: $px16;

    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    text-decoration: line-through;
    color: $grey-7;
  }

  &__final-price {
    font-size: 48px;
    line-height: 48px;
    font-weight: 600;
  }

  &--align-left {
    justify-content: flex-start;
  }

  &--m {
    #{$root}__original-price {
      font-size: 18px;
      line-height: 18px;
      font-weight: 500;
      text-decoration: line-through;
      color: $grey-7;
    }

    #{$root}__final-price {
      font-size: 32px;
      line-height: 32px;
      font-weight: 600;
    }
  }
}
