
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.promo-banner {
  $root: &;

  position: relative;
  padding: $px32 0;
  z-index: 5000;

  box-shadow: 0px 0px $px2 $px2 $grey-9;

  background-color: $cyan-10;

  &__container {
    @include container;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__body {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    max-width: 600px;
    margin-bottom: $px8;

    font-weight: 800;
  }

  &__logo {
    max-width: $px192;
    margin-right: $px16;
    margin-bottom: $px8;
    flex-shrink: 0;
  }

  @include breakpoint($md) {
    #{$root}__container {
      flex-direction: row;
    }

    #{$root}__body {
      margin-bottom: 0;
      flex-direction: row;
      align-items: center;
    }

    #{$root}__logo {
      margin-bottom: 0;
    }
  }
}
