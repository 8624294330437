
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.database-documentation {
  max-width: $px720;

  &__dataset-description {
    @include breakpoint($md) {
      padding-right: $px24;
    }
  }

  &__schema-button {
    display: none;

    @include breakpoint($md) {
      display: block;
    }
  }

  &__table-container {
    &:not(:last-child) {
      margin-bottom: $px32;
    }
  }

  &__table {
    tr {
      &:nth-child(odd) {
        background-color: $white;
      }

      &:nth-child(even) {
        background-color: $grey-10;
      }
    }

    td {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  &__table-name {
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
  }

  &__table-icon {
    height: 12px;

    color: $grey-3;
  }
}
