
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.practice-dropdown-menu-item {
  padding: $px8;

  border-radius: $px4;

  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: $px4;
  }

  &:hover {
    background-color: $grey-10;;
  }

  &--active {
    background-color: $grey-8;

    &:hover {
      background-color: $grey-8;
    }
  }
}
