
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.cookie-consent {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2147483650;
  display: flex;

  background-color: rgba($color: $grey-3, $alpha: 0.7);

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    transition: all .25s;
  }

  &__headline {
    display: flex;
    margin: 0 0 $px24;

    .emoji {
      margin-left: $px8;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    max-width: $px496;
    padding: $px24;

    border-radius: $px8;

    background-color: $grey-10;
  }

  &__description {
    margin-bottom: $px24;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    row-gap: $px8;

    @include breakpoint($md) {
      flex-direction: row;
      column-gap: $px16;
      row-gap: 0;
    }
  }

  &__adjust-settings-link {
    @include font-small;
  }
}
