
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.choose-subscription-step {
  &__free-pricing-plan {
    padding: $px24;

    text-align: center;
  }

  &__pricing-plans {
    display: grid;
    grid-template-columns: 1fr;
    gap: $px24;

    @include breakpoint($md) {
      grid-template-columns: 1fr 1fr;
    }
  }
}
