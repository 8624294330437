
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.schema-visualizer-button {
  $root: &;

  position: relative;
  overflow: hidden;
  flex-shrink: 0;

  border: $px1 solid $grey-9;
  border-radius: $px4;

  text-decoration: none;
  text-align: center;

  &__copy {
    @include font-regular;
    font-weight: 600;
  }

  &__snapshot {
    position: absolute;
    top: -$px8;
    left: -$px32;
    width: $px192;
    z-index: -1;
    opacity: 0.6;

    transform: rotate(-13deg);
  }

  &:hover {
    opacity: 1;

    border-color: $grey-8;

    #{$root}__copy {
      color: $grey-1;
    }

    #{$root}__snapshot {
      transform: scale(1.05) rotate(-13deg);
    }
  }
}
