
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.selected-text-ai-assistant-form {
  position: relative;
  z-index: 2000;
  min-width: $px320;
  padding: $px48 $px8 $px8;

  box-shadow: 0 0 $px8 $grey-9;
  border: $px1 solid $grey-9;
  border-radius: $px4;

  background-color: $white;

  &__name {
    width: 100%;
    padding: $px8;
    margin-bottom: $px16;

    border: $px1 solid $grey-8;
    border-radius: $px4;
    outline: none;
    appearance: none;
  }

  &__text-fragment {
    position: relative;
    max-width: $px320;
    margin-bottom: $px16;

    @include font-small;
    color: $grey-1;
  }

  &__comment {
    width: 100%;
    height: $px96;
    padding: $px8;
    margin-bottom: $px16;
    display: block;

    border: $px1 solid $grey-8;
    border-radius: $px4;
    outline: none;
    appearance: none;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__close {
    position: absolute;
    top: $px8;
    right: $px8;
    width: $px24;
    height: $px24;

    cursor: pointer;
    transition: all .25s;

    &:hover {
      opacity: 0.7;
    }
  }
}
