
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.blob-hero {
  position: relative;

  text-align: center;

  @include breakpoint($md) {
    padding: $px48;
  }

  &__headline {
    margin: 0 0 $px24;
    position: relative;
    z-index: 2;
  }

  &__description {
    margin-bottom: $px24;
    position: relative;
    z-index: 2;

    @include font-large;

    ul {
      margin: $px12 0 0;
      padding: 0;

      list-style: none;
    }
  }

  &__left-blob {
    position: absolute;
    left: 0;
    top: -$px48;
    z-index: -1;

    path {
      fill: $yellow-9;
    }
  }

  &__right-blob {
    width: $px144;
    position: absolute;
    right: -$px24;
    bottom: -$px48;
    z-index: -1;

    path {
      fill: $blue-9;
    }
  }
}
