
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.question-upvote-button {
  $root: &;

  display: inline-block;

  @include font-small;
  color: $grey-3;

  cursor: pointer;

  &__container {
    display: flex;
    align-items: center;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__icon {
    width: $px16;
    height: $px16;
    margin-right: $px4;

    color: $grey-3;
  }

  &--disabled {
    cursor: auto;

    #{$root}__container:hover {
      transform: scale(1);
    }
  }
}
