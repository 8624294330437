
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.job-ad-owner-menu {
  padding: $px16;

  border-radius: $px8;
  box-shadow: 0 0 $px8 $grey-9;

  background-color: $yellow-10;

  margin-bottom: $px32;

  &__headline {
    margin-bottom: $px16;
  }

  &__state {
    margin-bottom: $px24;

    @include font-small;
    color: $grey-3;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: $px8;
    }
  }

  &__item-hint {
    margin-left: $px8;

    @include font-small;
    color: $grey-5;
  }

  &__support-hint {
    margin-top: $px16;

    @include font-small;
    color: $grey-5;
  }
}
