
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.mark-lesson-as-completed {

    &__cta {
      display: flex;
      align-items: center;
      padding: $px16 $px16;

      border: 0;
      border-radius: $px4;
      outline: none;

      background-color: $green-7;

      color: $white;

      cursor: pointer;

      &:hover {
        background-color: $green-9;
      }
    }

    &__finished-icon {
      width: $px24;
      height: $px24;
      margin-left: $px8;

      fill: $white;
    }

    &__completed {
      display: flex;
      align-items: center;
    }

    &__lesson-status {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $px32;
      height: $px32;
      margin-left: $px8;

      border-radius: $px16;

      background-color: $green-7;

      color: $white;
    }

    &__lesson-finished-icon {
      width: $px24;
      height: $px24;
    }
  }
