
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.onboarding {
  position: relative;

  &__container {
    position: relative;
    padding-bottom: $px64;

    @include breakpoint($sm) {
      padding-bottom: 0;
    }

    @include breakpoint($md) {
      padding-top: $px32;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: $px24 $px24;
  }

  &__actions {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: $px16 0;

    text-align: right;

    @include breakpoint($sm) {
      padding: $px24;

      background-color: $grey-10
    }
  }

  &__actions-container {
    @include container;
  }

  &__skip-step {
    margin-right: $px16;

    @include font-small;
  }

  &__next-cta {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;

    border-radius: 0;

    @include breakpoint($sm) {
      position: relative;
      left: auto;
      bottom: auto;
      width: auto;

      border-radius: $px4;
    }
  }

  &__steps-counter {
    font-weight: 600;
  }

  &__progress-bar {
    display: flex;
    justify-content: space-between;
    gap: $px8;
    width: 100%;
  }

  &__progress-bar-step {
    height: $px8;

    background-color: $grey-8;

    transition: background-color .4s;

    &--active {
      background-color: $blue-8;
    }

    &--clickable {
      cursor: pointer;

      &:hover {
        background-color: $blue-7;
      }
    }
  }

  // Steps animation
  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    display: none;
  }

  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }
}
