
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.subscription-pricing-plans {
  $root: &;

  &__plans {
    display: flex;
    gap: $px32;
    align-items: flex-start;
    flex-direction: column-reverse;

    @include breakpoint($md) {
      display: grid;
      flex-direction: row;
      gap: $px16;
    }
  }

  &__plan {
    width: 100%;
  }

  &--2 {
    #{$root}__plans {
      @include breakpoint($md) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  &--3 {
    #{$root}__plans {
      @include breakpoint($md) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
