
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.blog-info-panel {
  display: flex;
  padding: $px4 0;
  position: relative;

  border-radius: $px8;

  background-color: $blue-10;

  &__icon {
    position: absolute;
    top: 25px;
    left: $px24;
    width: $px24;
    height: $px24;

    color: $grey-4;
  }

  &__copy {
    padding: 0 $px24 0 70px;
  }
}
