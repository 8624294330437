
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.confirmation-popup {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20000;
  padding: $px16;
  width: $px496;

  border: $px1 solid $grey-8;
  border-top: 0;
  border-radius: 0 0 $px4 $px4;
  box-shadow: 0 $px4 $px8 0 $grey-7;

  background-color: $white;

  &__copy {
    margin-bottom: $px8;
  }

  &__actions {
    text-align: right;
  }
}
