
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.db-compatibility-drawer {
  $root: &;

  display: hidden;
  height: auto;

  &__status {
    overflow-wrap: break-word;
  }

  &__check-icon {
    color: $green-5;
  }

  &__times-icon {
    color: $red-5;
  }

  &__note {
    margin-top: $px16;
  }

  &__related-pages {
    margin-top: $px16;
  }

  &--active {
    display: flex;
    flex-direction: column;
  }

  &--mobile {
    #{$root}__status {
      padding: $px16;
    }

    #{$root}__note {
      margin-top: 0;
      padding: $px16;
    }

    #{$root}__related-pages {
      margin-top: 0;
      padding: $px16;
    }
  }
}
