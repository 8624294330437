
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.practice-menu {
  &__menu-items {
    display: flex;
  }

  &__menu-item {
    &:not(:last-child) {
      margin-right: $px16;
    }
  }

  &__search {
    flex-grow: 1;
  }

  &__active-menu-items {
    margin-top: $px24;
  }
}
