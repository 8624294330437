
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.selectable-card {
  padding: $px16;
  width: 100%;
  position: relative;

  border: $px2 solid $grey-8;
  border-radius: $px12;

  background-color: $white;

  text-align: left;

  transition: border-color .3s, background-color .3s;
  cursor: pointer;

  &:hover {
    border-color: $grey-7;

    background-color: $grey-10;
  }

  &--selected {
    border-color: $blue-8;

    &:hover {
      border-color: $blue-8;
    }
  }

  @include breakpoint($sm) {
    width: 33%;
  }

  &__emoji {
    width: 44px;
    height: 44px;
    padding: $px12;
    margin-bottom: $px24;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-shrink: 0;

    font-size: $px20;
    line-height: 1;

    border-radius: 50%;

    background-color: $grey-10;

    appearance: none;
  }

  &__name {
    margin-bottom: $px8;

    font-weight: 600;
    letter-spacing: 0.65px;
  }

  &__checked-icon {
    position: absolute;
    top: $px8;
    right: $px8;
    width: $px24;
    height: $px24;

    color: $blue-7;
  }
}
