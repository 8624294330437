
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.feedback-rating {
    width: 100%;

    &__header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: $px8;
    }

    &__header-icon {
      width: $px24;
      height: $px24;
      margin-right: $px8;
    }

    &__form {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }

    &__input {
      width: 100%;
      height: $px144;
      margin-bottom: $px8;
      padding: $px8 $px16;

      border: $px1 solid $grey-7;
      border-radius: $px4;

      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      @include breakpoint($md) {
        width: $px496;
      }
    }

    &__submit {
      display: inline-block;
      padding: $px12 $px16;

      border: none;
      border-radius: $px4;

      background-color: $blue-6;

      color: $white;

      cursor: pointer;
      outline: none;

      &:hover {
        opacity: 0.7;
      }
    }

    @include breakpoint($md) {
      width: auto;
    }
  }
