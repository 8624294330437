
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

@keyframes slideInDown {
  from {
    transform: translate3d(-50%, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    transform: translate3d(-50%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(-50%, -100%, 0);
  }
}

.slide-in-up {
  animation: slideInUp 0.2s ease-in-out both;
}

.slide-in-down {
  animation: slideInDown 0.2s ease-in-out both;
}

.mock-interview-exercise {
  $root: &;

  overflow: hidden;

  &__name {
    @include h1;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__submit {
    margin-right: $px24;
  }

  &__description {
    margin-bottom: $px24;

    p:first-child {
      margin-top: 0;
    }

    .table {
      overflow-x: auto;
    }

    table {
      border: $px1 solid $grey-7;
      border-radius: $px4;
      border-collapse: collapse;

      tr:first-child {
        background-color: $grey-7;

        font-weight: 700;
      }

      td {
        white-space: nowrap;
      }

      tr {
        &:nth-child(even) {
          background-color: $grey-10;
        }
      }

      th,
      td {
        padding: $px4 $px8;

        text-align: left;
      }
    }
  }

  &__left-column {
    width: 45%;
    padding-right: $px32;
  }

  &__current-query {
    pre {
      white-space: pre-line;
    }

    code {
      white-space: pre;
    }
  }

  &__sql-editor {
    width: 55%;
  }

  &__workspace {
  }

  &__skip {
    @include font-link;

    &--disabled {
      color: $grey-7;
    }
  }
}
