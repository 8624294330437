
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.practice-active-menu-items {
  $root: &;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: $px8;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $px4 $px8;

    border-radius: $px12;

    background-color: $grey-9;

    @include font-small;

    &:hover {
      cursor: pointer;

      #{$root}__item-close-icon {
        color: $grey-6;
      }
    }
  }

  &__reset-link {
    flex-shrink: 0;
    margin-left: $px16;

    @include font-small;
  }

  &__item-close-icon {
    margin-left: $px4;
    width: $px16;
    height: $px16;
  }
}
