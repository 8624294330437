
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.auth-popup {
  position: relative;
  padding: $px48 $px24 $px16;
  overflow: hidden;

  border-radius: $px4;

  background-color: $white;

  &--square-illustration {
    &:after {
      display: none;
      position: absolute;
      z-index: 2;
      width: 350px;
      height: 350px;
      top: -75px;
      left: -50px;

      border-radius: $px16;

      background-color: $orange-10;
      opacity: 0.8;

      content: "";
      transform: rotate(80deg);

      @include breakpoint($md) {
        display: block;
      }
    }
  }

  &--circle-illustration {
    &:after {
      display: none;
      position: absolute;
      z-index: 2;
      width: 350px;
      height: 350px;
      top: -75px;
      left: -50px;

      border-radius: 175px;

      background-color: $blue-10;
      opacity: 0.8;

      content: "";
      transform: rotate(80deg);

      @include breakpoint($md) {
        display: block;
      }
    }
  }

  &__close-icon {
    position: absolute;
    top: $px8;
    right: $px8;
    width: $px24;
    height: $px24;
    z-index: 2;

    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__body {
    position: relative;
    z-index: 3;
  }

  &__tos {
    margin-top: $px24;

    @include font-legal;

    a {
      @include font-legal;
    }
  }
}
