
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.continue-banner {
  $root: &;

  position: relative;
  padding: 0;
  overflow: hidden;

  border-radius: $px4;

  background-color: $grey-10;

  @include breakpoint($sm) {
    padding: 0 $px24;
  }

  &__bg {
    position: absolute;
    width: 300px;
    height: 300px;
    top: -200px;
    left: -100px;
    z-index: 1;

    border-radius: 150px;

    background-color: $cyan-9;

    opacity: 0.8;
  }

  &__link-wrapper {
    display: flex;
    align-items: center;
    padding: $px24;

    text-decoration: none;

    &:hover {
      opacity: 1;

      #{$root}__play-icon {
        width: $px72;
        height: $px72;

        color: $blue-5;
      }
    }
  }

  &__content {
    position: relative;
    z-index: 2;
  }

  &__headline {
    margin-top: 0;
    margin-bottom: $px16;
  }

  &__play-icon {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    width: $px64;
    height: $px64;

    color: $blue-6;

    transition: width 0.5s, height 0.5s;

    @include breakpoint($md) {
      display: block;
    }
  }
}
