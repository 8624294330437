
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.login-popup {
  width: 100%;

  border-radius: $px4;

  @include breakpoint($md) {
    width: $px720;
  }

  &__headline {
    margin: 0 0 $px24;
  }

  &__body {
    display: flex;
    flex-direction: column-reverse;

    @include breakpoint($md) {
      flex-direction: row;
    }
  }

  &__info {
    width: 100%;
    position: relative;

    @include breakpoint($md) {
      width: 50%;
    }
  }

  &__form-and-actions {
    width: 100%;

    @include breakpoint($md) {
      width: 50%;
    }
  }

  &__form {
    margin-bottom: $px24;
  }

  &__avatar {
    max-width: 300px;

    @include breakpoint($md) {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;

    text-align: right;
  }

  &__toggle-link {
    display: block;
    margin-bottom: $px8;

    @include font-small;
    font-weight: 600;
    text-decoration: none;
  }

  &__reset-password-link {
    @include font-small;
    font-weight: 600;
    text-decoration: none;
  }
}
