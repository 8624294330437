
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.practice-exercise-evaluation {
  $root: &;

  &__name {
    display: flex;
    align-items: baseline;

    @include h1;

    .emoji {
      height: 0.7em;
      width: 0.7em;
      margin-right: $px12;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__submit {
    margin-right: $px24;
  }

  &__description {
    margin-bottom: $px24;

    p:first-child {
      margin-top: 0;
    }

    .table {
      overflow-x: auto;
    }

    table {
      border: $px1 solid $grey-7;
      border-radius: $px4;
      border-collapse: collapse;

      tr:first-child {
        background-color: $grey-7;

        font-weight: 700;
      }

      td {
        white-space: nowrap;
      }

      tr {
        &:nth-child(even) {
          background-color: $grey-10;
        }
      }

      th,
      td {
        padding: $px4 $px8;

        text-align: left;
      }
    }
  }

  &__left-column {
    width: 45%;
    padding-right: $px32;
  }

  &__sql-editor {
    width: 55%;
  }

  &__skip {
    @include font-link;

    &--disabled {
      color: $grey-7;
    }
  }

  &__status {
    padding: $px12 $px16;

    border-radius: $px8;

    &--solved {
      background-color: $green-9;
    }

    &--unsolved {
      background-color: $yellow-9;
    }
  }

  &__wrong-column-names {
    padding: $px12 $px16;

    border-radius: $px8;

    background-color: $yellow-9;
  }

  &__explanation {
    max-height: $px96;
    overflow: hidden;

    &--revealed {
      max-height: none;
      overflow: auto;
    }
  }

  &__user-solution {
    line-height: 2;

    pre {
      white-space: pre-line;
    }

    code {
      white-space: pre;
    }
  }

  &__actions {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__explanation-fadeaway {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 0;

    background-image: linear-gradient(to bottom, rgba($white, 0) 0%, $white 100%);;
  }

  &__reveal-explanation-button {
    position: absolute;
    width: 80%;
    bottom: $px8;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
}
