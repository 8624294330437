
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.receipt-customer-info {
  &__textarea {
    display: block;
    width: 100%;
    margin-bottom: $px8;
    min-height: $px144;
    padding: $px8;

    line-height: 1.5;
  }

  &__customer-info {
    white-space: pre;
  }

  &__edit-link {
    display: flex;
    align-items: center;

    font-weight: 800;

    svg {
      width: $px20;
      height: $px20;
    }
  }
}
