
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.features-nav {
  display: flex;
  align-items: center;

  &__item {
    width: $px12;
    height: $px12;

    border-radius: 50%;

    background-color: $grey-6;

    @include font-small;

    cursor: pointer;

    &--active {
      background-color: $blue-6;

      color: $white;
    }

    &:not(:last-child) {
      margin-right: $px8;
    }
  }

  &--reversed {
    justify-content: flex-end;
  }
}
