
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.mdn-page-toc {
  $root: &;

  position: sticky;
  top: $px24;

  h3#{$root}__headline {
    font-size: $px20;
  }

  &__item {
    display: block;
    padding: $px8 $px16;

    background-color: $grey-10;

    font-size: $px16;

    &:hover {
      background-color: $blue-9;

      opacity: 1;
    }

    &:first-child {
      border-radius: $px8 $px8 0 0;
    }

    &:last-child {
      padding-bottom: $px12;

      border-radius: 0 0 $px8 $px8;
    }

    &--h3 {
      padding-left: $px24;
    }

    &--active {
      background-color: $blue-9;
    }
  }
}
