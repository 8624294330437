
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.team-size-calculator {
  &__form {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    @include breakpoint($md) {
      flex-direction: row;
    }
  }

  &__size-input {
    width: 100%;

    @include breakpoint($md) {
      width: auto;
      margin-right: $px8;
    }
  }

  &__cta {
    display: inline-block;
    width: 100%;
    margin-top: $px16;

    @include breakpoint($md) {
      width: auto;
    }
  }

  &__prices {
    display: flex;
    align-items: center;
    margin-bottom: $px16;
  }

  &__original-price {
    margin-right: $px16;

    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    text-decoration: line-through;
    color: $grey-7;
  }

  &__final-price {
    font-size: 48px;
    line-height: 48px;
    font-weight: 600;
  }
}
