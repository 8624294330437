
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.search-results {
  max-height: 464px;
  overflow-y: scroll;

  &__results-count {
    @include font-small;
    font-style: italic;
  }

  &__item {
    display: block;
    padding: $px8 $px16;

    text-decoration: none;

    mark {
      background-color: $yellow-8;
    }

    &:hover {
      background-color: $blue-3;

      color: $white;

      mark {
        background-color: $blue-3;

        color: $white;
      }
    }

    &:last-child {
      border-radius: 0 0 $px8 $px8;
    }
  }

  &__item-name {
    margin-bottom: $px4;
  }

  &__item-chapter {
    @include font-small;
  }

  &__no-results {
    padding: $px8 $px16;

    @include font-small;
  }
}
