
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.welcome-checklist {
  position: relative;
  max-width: $px720;
  overflow: hidden;

  border-radius: $px8;

  &:before {
    position: absolute;
    width: 300px;
    height: 300px;
    top: -150px;
    left: -100px;
    z-index: 1;

    border-radius: 150px;

    background-color: $yellow-9;

    opacity: 0.8;
    content: "";
  }

  &:after {
    position: absolute;
    width: 400px;
    height: 400px;
    bottom: -200px;
    right: -150px;
    z-index: 1;

    border-radius: $px24;

    background-color: $orange-9;

    opacity: 0.8;
    content: "";
    transform: rotate(-30deg);
  }

  &__container {
    padding: $px24;

    background-color: $grey-10;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $px24;
    position: relative;
    z-index: 2;
  }

  &__headline {
    margin: 0;
    padding: 0;
  }

  &__close-link {
    display: flex;
    align-items: center;
    position: absolute;
    top: $px12;
    right: $px16;

    @include font-small;
  }

  &__item {
    position: relative;
    z-index: 2;

    &:not(:last-child) {
      margin-bottom: $px20;
    }
  }
}
