
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.job-ad-banner {
  &__link-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: $px16 $px24;
    max-width: $px720;
    position: relative;

    border: 0;
    border-radius: $px4;

    background-color: $grey-10;

    text-decoration: none;

    transition: all 0.25s ease-in;

    &:before {
      display: block;
      width: $px8;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      border-radius: $px8 0 0 $px8;

      background-color: $purple-7;

      content: "";
    }

    &:hover {
      transform: scale(1.01);
    }
  }

  &__position {
    display: flex;
    align-items: center;
    margin-bottom: $px8;
  }

  &__caption {
    margin-bottom: $px16;
  }

  &__company-logo {
    width: $px64;
    height: $px64;
    margin-right: $px8;

    border-radius: $px8;
  }

  &__position-name {
    margin-bottom: $px4;

    @include font-caption;
  }

  &__company-name {
    @include font-small;
    color: $grey-3;
  }

  &__tags {
    display: flex;
    gap: $px4;
  }

  &__tag {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: $px4;

    background-color: $grey-10;

    @include font-micro;

    &:not(:last-child) {
      margin-right: $px24;
    }
  }

  &__tag-icon {
    width: $px16;
    height: $px16;
    margin-right: $px4;
  }

  &__settings-bar {
    display: flex;
    margin-top: $px4;

    @include font-legal;
  }

  &__settings-bar-item {
    &:not(:last-child) {
      margin-right: $px8;
    }
  }
}
