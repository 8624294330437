
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.five-star {
  display: flex;
  flex-direction: column;

  &__question {
    margin-bottom: $px8;
  }

  &__stars {
    display: flex;
    align-items: center;
  }

  &__rating-button {
    &:not(:last-child) {
      padding-right: $px16;
    }

    &:hover {
      opacity: 1;
    }
  }

  &__rating-button-body {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
  }

  &__rating-button-label {
    display: flex;
    margin-top: $px8;
    min-height: 18px;

    @include font-small;
    word-break: nowrap;

    &--1 {
      margin-left: 30px;
    }

    &--2 {
      margin-left: 30px;
    }

    &--3 {
      justify-content: center;
    }

    &--4 {
      justify-content: end;
      margin-right: 30px;
    }

    &--5 {
      justify-content: end;
    }
  }

  &__rating-icon {
    width: 38px;
    height: 38px;
  }
}
