
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.team-subscription-size-calculator {
  $root: &;

  position: relative;
  min-width: $px384;
  max-width: $px496;
  padding: $px24;

  border-radius: $px8;

  background-color: $grey-10;

  &__headline {
    @include h3;
    font-weight: 700;
  }

  &__size-label {
    @include font-label;
  }

  &__size-input {
    width: 100%;
    padding: $px8;

    border: $px2 solid $grey-7;
    border-radius: $px4;
    outline: none;

    font-size: $px24;
    line-height: 1.5;

    appearance: none;

    &::placeholder {
      font-size: $px24;
      line-height: 1.5;
      color: $grey-7;
    }
  }

  &__cta {
    display: inline-block;
    width: 100%;
    margin-top: $px16;

    @include breakpoint($md) {
      width: auto;
    }
  }

  &__prices {
    display: flex;
    align-items: center;
  }

  &__original-price {
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    text-decoration: line-through;
    color: $grey-7;
  }

  &__final-price {
    font-size: $px32;
    line-height: $px32;
    font-weight: 700;
  }

  &__per-year {
    margin-top: auto;

    font-size: $px20;
    line-height: 1;
  }

  &__billing-price {
    margin-top: $px8;

    @include font-small;
    text-align: center;

    &--striked {
      color: $grey-2;
      text-decoration: line-through;
    }
  }

  &__savings {
    position: absolute;
    right: $px16;
    top: 0;
    transform: translateY(-50%);
    padding: $px4 $px12;

    border-radius: $px16;

    background-color: $purple-5;

    color: $white;
    font-size: 15px;
    font-weight: 700;
  }
}
