$white: #FFF;

$grey-1: #1F2933;
$grey-2: #323F4B;
$grey-3: #3E4C59;
$grey-4: #52606D;
$grey-5: #616E7C;
$grey-6: #7B8794;
$grey-7: #9AA5B1;
$grey-8: #CBD2D9;
$grey-9: #E4E7EB;
$grey-10: #F5F7FA;

$blue-1: #002159;
$blue-2: #01337D;
$blue-3: #03449E;
$blue-4: #0552B5;
$blue-5: #0967D2;
$blue-6: #2186EB;
$blue-7: #47A3F3;
$blue-8: #7CC4FA;
$blue-9: #BAE3FF;
$blue-10: #E6F6FF;

$cyan-1: #05606E;
$cyan-2: #07818F;
$cyan-3: #099AA4;
$cyan-4: #0FB5BA;
$cyan-5: #1CD4D4;
$cyan-6: #3AE7E1;
$cyan-7: #62F4EB;
$cyan-8: #92FDF2;
$cyan-9: #C1FEF6;
$cyan-10: #E1FCF8;

$orange-1: #841003;
$orange-2: #AD1D07;
$orange-3: #C52707;
$orange-4: #DE3A11;
$orange-5: #F35627;
$orange-6: #F9703E;
$orange-7: #FF9466;
$orange-8: #FFB088;
$orange-9: #FFD0B5;
$orange-10: #FFE8D9;

$red-1: #610316;
$red-2: #8A041A;
$red-3: #AB091E;
$red-4: #CF1124;
$red-5: #E12D39;
$red-6: #EF4E4E;
$red-7: #F86A6A;
$red-8: #FF9B9B;
$red-9: #FFBDBD;
$red-10: #FFE3E3;

$yellow-1: #8D2B0B;
$yellow-2: #B44D12;
$yellow-3: #CB6E17;
$yellow-4: #DE911D;
$yellow-5: #F0B429;
$yellow-6: #F7C948;
$yellow-7: #FADB5F;
$yellow-8: #FCE588;
$yellow-9: #FFF3C4;
$yellow-10: #FFFBEA;

$green-1: #014D40;
$green-2: #0C6B58;
$green-3: #147D64;
$green-4: #199473;
$green-5: #27AB83;
$green-6: #3EBD93;
$green-7: #65D6AD;
$green-8: #8EEDC7;
$green-9: #C6F7E2;
$green-10: #EFFCF6;

$purple-1: #0C008C;
$purple-2: #1D0EBE;
$purple-3: #3525E6;
$purple-4: #4D3DF7;
$purple-5: #5D55FA;
$purple-6: #7069FA;
$purple-7: #8888FC;
$purple-8: #A2A5FC;
$purple-9: #C4C6FF;
$purple-10: #E6E6FF;

$pink-1: #8C004C;
$pink-2: #BE0064;
$pink-3: #E6007D;
$pink-4: #F73794;
$pink-5: #FA559E;
$pink-6: #FA6AAF;
$pink-7: #FC88BF;
$pink-8: #FCA2CF;
$pink-9: #FFC4E0;
$pink-10: #FFE6F0;

$linkedin-blue: #0077B5;

.dark-grey {
  color: $grey-4;
}
