
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.markdown-cheatsheet {
  width: 100%;

  &__description {
    display: flex;
  }

  &__toggle-link {
    display: flex;
    align-items: center;
    margin-left: $px4;
  }

  &__toggle-link-icon {
    width: $px16;
    height: $px16;
  }

  &__cheatsheet {
    margin-top: $px16;
    padding: $px16;

    border: $px1 solid $grey-9;
    border-radius: $px4;
  }

  &__cheatsheet-item {
    &:not(:last-child) {
      margin-bottom: $px16;
    }

    pre {
      white-space: pre-line;
    }

    .language-pgsql.hljs {
      padding-bottom: $px16;
    }
  }
}
