.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@keyframes tooltip-shake {
  10%, 90% {
    transform: translate3d(-1px, -50%, 0);
  }

  20%, 80% {
    transform: translate3d(2px, -50%, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, -50%, 0);
  }

  40%, 60% {
    transform: translate3d(4px, -50%, 0);
  }
}

// https://github.com/animate-css/animate.css/blob/9319952c1017ba84bc056a8545916d68168c1d53/source/flippers/flipInX.css
@keyframes flipInX {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animation--flip-in-x {
  backface-visibility: visible !important;
  animation-name: flipInX;
}

.animation {
  animation-duration: 0.75s;
  animation-fill-mode: both;
}
