
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.page-feedback-hover-menu {
  width: 100%;

  box-shadow: 0 $px4 $px16 0 $grey-8;

  border-radius: $px8;
  border: 1px solid $grey-8;

  background-color: $grey-10;

  @include breakpoint($sm) {
    width: auto;
    max-width: $px496;
  }

  &__option {
    display: flex;
    align-items: center;
    padding: $px16;

    text-decoration: none;

    cursor: pointer;

    &:first-child {
      border-radius: $px8 $px8 0 0;
    }

    &:last-child {
      border-radius: 0 0 $px8 $px8;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $grey-8;
    }

    &:hover {
      opacity: 1;

      background-color: $grey-9;
    }
  }

  &__icon-wrapper {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    border-radius: 50%;

    background-color: $grey-8;
  }

  &__icon {
    width: $px24;
    height: $px24;
    flex-shrink: 0;

    &--email {
      width: $px20;
      height: $px20;
    }
  }

  &__title {
    font-weight: 700;
  }

  &__description {
    @include font-small;
  }
}
