
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              
$toast-colors: () !default;
$toast-colors: map-merge(
  (
    "success": $green-7,
    "info": $blue-7,
    "warning": $yellow-8,
    "error": $red-6,
    "default": $green-7
  ),
  $toast-colors
);

.v-toast {
  padding: $px24 $px16;
  z-index: 2147483649; // Navbar + 2

  &__item {
    min-width: $px384;
    max-width: $px496;

    border-radius: $px8;;

    opacity: 1;

    @each $color, $value in $toast-colors {
      &--#{$color} {
        background-color: $value;
      }
    }

    a {
      color: $white;
    }
  }
}
