
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.thumbs-rating {
  display: flex;
  align-items: center;

  &__question {
    margin-right: $px8;
  }

  &__rating-button {
    &:not(:last-child) {
      margin-right: $px16;
    }
  }

  &__rating-button-body {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $px48;
    height: $px48;

    border: $px1 solid $grey-7;
    border-radius: $px24;

    &--thumbs-up {
      padding-bottom: $px4;
    }

    &--thumbs-down {
      padding-top: $px4;
    }
  }

  &__rating-icon {
    width: $px24;
    height: $px24;
  }
}
