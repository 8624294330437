
                @import "./app/assets/stylesheets/design_system/_spacing.scss";
                @import "./app/assets/stylesheets/design_system/_colors.scss";
                @import "./app/assets/stylesheets/design_system/_breakpoint.scss";
                @import "./app/assets/stylesheets/design_system/_grid.scss";
                @import "./app/assets/stylesheets/design_system/_typography.scss";
                @import "./app/assets/stylesheets/design_system/_animations.scss";
              

.mock-interview-sql-editor {
  &__query-editor {
    width: 100%;
    height: 250px;
    margin-bottom: $px8;

    border: $px1 solid $grey-9;
    border-radius: $px4;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__database-name {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: $px24;

    @include font-small;
    color: $grey-5;
    text-decoration: none;
  }

  &__database-name-icon {
    width: $px24;
    height: $px24;
    margin-left: $px4;
  }
}
